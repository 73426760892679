
div.trainings {
  @extend .columns;

  margin-top: 1em;

  div.training {
    @extend .column;
    @extend .box;
    @extend .is-fullwidth;
    @extend .has-text-centered;

    padding: 1.5em;
    padding-bottom: 2em;

    margin: 0.75em;
    margin-bottom: 20px;

    p {
      text-align: center;
      padding-bottom: 0.5em; }

    .logo {
      padding-top: 2em;
      img {
        width: 60%;
        max-width: 20em; } }

    a.action-button {
      margin-top: 1em; } } }
