.lectors {
  @extend .container;

  .lector {
    @extend .box;

    &.inactive {
      /* Theoretically for IE 8 & 9 (more valid) */
      /* ...but not required as filter works too */
      /* should come BEFORE filter */
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';

      /* This works in IE 8 & 9 too */
      /* ... but also 5, 6, 7 */
      filter: alpha(opacity=50); // IE 5-7

      /* Modern Browsers */
      opacity: 0.5; }

    .profile {
      img {
        border-radius: 100%; } }

    a.level-item {
      border: none; }

    .media-left {
      padding-right: 10pt; } } }
