
div.tabs {
  @extend .tabs;
  @extend .is-boxed;
  @extend .is-centered;

  ul {
    margin-left: 0;
    margin-right: 0; }

  li.tab {
    .logo {
      vertical-align: bottom;
      padding-right: 0.75em;

      img {
        height: 2em; } }

    h4 {
      margin-bottom: 0.2em; } } }

span.price {
  font-size: 1.25em;
  font-weight: bold; }
