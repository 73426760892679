.webs {
  @extend .container;

  .web {
    @extend .box; }

  .media-content {
    margin-left: 15pt; }

  a.action-button {
    margin-top: 1em; } }
