
#top-menu {
  display: none;

  @media screen and (max-width: $withMenu) {
    display: block; }

  background: $top-menu-background;
  color: $top-menu-color;
  position: fixed;
  width: 100%;
  min-height: 40px;
  z-index: 101;

  a {
    border: none; }

  .site-name {
    @extend .is-pulled-left;
    height: 2em;
    font-size: 1.6em;
    font-weight: bold;
    padding: 0.4em 0.4em 0.04em;

    a {
      color: $top-menu-color !important; } }

  .menu-button {
    @extend .is-pulled-right;
    background: $primary;

    .button {
      @extend .is-primary;
      color: $top-menu-hamburger-color;
      border-radius: 0;
      width: 5em;
      height: 4em; } }

  .menu-list {
    display: none;
    margin-top: 60px;
    background: $primary;
    color: $menu-link-color;

    a {
      color: $menu-link-color !important;

      &.is-active {
        color: $menu-link-active-color !important; } } } }


