body {
  background-color: $white;
  font-size: 1.25em;
  line-height: 1.8em; }

h1, h2, h3, h4, h5, h6 {
  color: $text-strong;
  font-weight: $weight-title-normal;
  line-height: 1em;
  margin: 0 0 (2.25em * 0.25) 0; }

h2 {
  font-size: 2em;
  line-height: 1.5em; }

h3 {
  font-size: 1.75em;
  line-height: 1.5em; }

h4 {
  font-size: 1.25em;
  line-height: 1.5em; }

h5 {
  font-size: 0.9em;
  line-height: 1.5em; }

h6 {
  font-size: 0.7em;
  line-height: 1.5em; }

.section {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  ul {
    margin: 0.25em 1em 0.25em 2em;
    list-style: disc outside; } }

.main-section,.head {
  a:not(.image) {
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    border-bottom: solid 1px $border; }

  a:visited {
    color: $link !important; }

  a:hover {
    color: $link-hover !important;
    border-bottom-color: $link-hover-border !important; }

  a.button:hover {
    border-bottom-color: $border !important; }

  a.action-button {
    @extend .button;
    @extend .is-medium;
    @extend .is-primary;

    &:visited {
      color: $primary-invert !important; } } }

.head {
  h2 {
    color: $primary;
    font-size: 3.5em;
    font-weight: $weight-bold; }

  h3 {
    color: $dark;
    font-size: 1.75em;
    font-weight: $weight-bold; } }

.content, .container, .section {
  p {
    text-align: justify;
    padding-bottom: 0.75em; }

  .is-bolder {
    font-weight: 600;

    p {
      font-weight: 600; } }

  p {} }

.text-container {
  @extend .container;
  text-align: justify; }

footer .container p {
  text-align: center; }

.other-content {
  display: none; }

.icon.is-small {
  vertical-align: middle;
  padding-left: 3pt; }

.more-info {
  font-style: italic;
  font-size: 0.95em;
  padding-left: 0.5em; }


