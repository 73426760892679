.events {
  margin-top: 3em; }

.reference, .event {
  @extend .columns;
  align-items: center;
  margin: 2.5em 1em;

  @media screen and (max-width: $desktop) {
    text-align: center; }


  .logo,.button {
    @extend .column;
    @extend .is-narrow;
    min-width: 15em;

    .image {
      max-width: 150px; }

    .image, .button {
      margin: 0 auto; } }

  .content {
    @extend .column; }

  .subtitle {
    font-size: 1.3em;
    margin-bottom: 0.25em; } }
