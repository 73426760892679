
.menu-list {
  text-align: center;

  li {
    display: block;
    font-weight: bolder;

    a {
      padding: 0.6em 0;
      color: $menu-link-color; }

    a:hover {
      background-color: $menu-link-hover-background;
      color: $menu-link-hover-color; }

    a.is-active {
      background: $menu-link-active-background;
      color: $menu-link-active-color; } }

  li:not(:first-child) {
    border-top: solid 2px $menu-link-top-border; } }
