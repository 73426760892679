body {
  font-size: 12pt;

  @media screen and (max-width: $mobile) {
    font-size: 15pt; }

  @media screen and (min-width: $mobile) and (max-width: $tablet) {
    font-size: 14pt; }

  @media screen and (min-width: $tablet) and (max-width: $desktop) {
    font-size: 13pt; } }

.main {
  max-width: 48em;
  margin: 0 auto;
  flex: auto; }

.wrapper {
  display: flex; }

.side-bar {
  display: block;
  flex: 0 0 18em;

  @media screen and (max-width: $withMenu) {
    display: none; }

  .fixed-content {
    width: 18em;
    position: fixed;
    top: 0; } }

.main-section {
  @extend .section;
  padding: 2em 1em 1em;

  &:not(:first-child) {
    border-top: solid 1px $grey-lighter; } }

footer {
  margin-top: 10em; }

