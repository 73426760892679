@import '../../node_modules/bulma/bulma';

@import 'basics';
@import 'layout';
@import 'sidebar';
@import 'top-menu';
@import 'menu';
@import 'lectors';
@import 'reference-events';
@import 'contact';
@import 'materials';
@import 'price-info';
@import 'offer';
@import 'trainings';
@import 'trainings-blocks';
@import 'webs';
