// Override variables here

//$blue: $color-accent-bg // Update blue
//$family-serif: 'Lato', sans-serif // Add a serif family

// 1. Initial variables

// Colors
@import "../../../node_modules/bulma/sass/utilities/functions";

$black: #111 !default;
$grey-darker: #222324 !default;
$grey-dark: #69707a !default;
$grey: #aeb1b5 !default;
$grey-light: #d3d6db !default;
$grey-lighter: #f5f7fa !default;
$white: #fff !default;

$blue: #42afe3 !default;
$green: #97cd76 !default;
$orange: #f68b39 !default;
$purple: #847bb9 !default;
$red: #ed6c63 !default;
$turquoise: #1fc8db !default;
$yellow: #fce473 !default;

// Typography

$family-sans-serif: "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

$family-monospace: 'Source Code Pro', monospace !default;

$size-1: 48px !default;
$size-2: 40px !default;
$size-3: 28px !default;
$size-4: 24px !default;
$size-5: 18px !default;
$size-6: 14px !default;

$size-7: 11px !default;

$weight-normal: 400 !default;
$weight-bold: 700 !default;
$weight-title-normal: 300 !default;
$weight-title-bold: 500 !default;

// Breakpoints

$mobile: 425px !default;
$tablet: 769px !default;
$desktop: 980px !default;
$withMenu: 1024px !default;
$widescreen: 1180px !default;

// Dimensions

$column-gap: 20px !default;

$nav-height: 50px !default;

// Miscellaneous

$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 3px !default;
$radius-large: 5px !default;
$speed: 86ms !default;

// 2. Primary colors

$primary: $turquoise !default;

$info: $blue !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;

$light: $grey-lighter !default;
$dark: $grey-dark !default;

$text: $grey-dark !default;

// 3. Generated variables

// Invert colors

$primary-invert: findColorInvert($primary) !default;

$info-invert: findColorInvert($info) !default;
$success-invert: findColorInvert($success) !default;
$warning-invert: findColorInvert($warning) !default;
$danger-invert: findColorInvert($danger) !default;

$light-invert: $dark !default;
$dark-invert: $light !default;

// General colors

$body-background: $grey-lighter !default;

$background: $grey-lighter !default;

$border: $grey-light !default;
$border-hover: $grey !default;

// Text colors

$text-invert: findColorInvert($text) !default;
$text-light: $grey !default;
$text-strong: $grey-dark !default;

// Code colors

$code: $red !default;
$code-background: $background !default;

$pre: $text !default;
$pre-background: $background !default;

// Link colors

$link: $text !default;
$link-invert: $primary-invert !default;
$link-visited: $text !default;

$link-hover: $primary !default;
$link-hover-background: $grey-lighter !default;
$link-hover-border: transparent !default;

$link-active: $white !default;
$link-active-border: $white !default;

// My link colors

$menu-link-color: $primary-invert !default;
$menu-link-hover-color: $primary-invert !default;
$menu-link-hover-background: $primary !default;
$menu-link-active-color: $primary !default;
$menu-link-active-background: $primary-invert !default;
$menu-link-top-border: $light !default;

$top-menu-background: $dark !default;
$top-menu-color: $primary-invert !default;
$top-menu-hamburger-color: $top-menu-color !default;

// Control colors

$control: $text-strong !default;
$control-background: $text-invert !default;
$control-border: $border !default;

$control-hover: $link-hover !default;
$control-hover-border: $border-hover !default;

$control-active: $link !default;
$control-active-background: $link !default;
$control-active-background-invert: $link-invert !default;
$control-active-border: $link !default;

// Typography

$family-primary: $family-sans-serif !default;
$family-code: $family-monospace !default;

$size-small: $size-7 !default;
$size-normal: $size-6 !default;
$size-medium: $size-5 !default;
$size-large: $size-3 !default;
$size-huge: $size-1 !default;

// 4. Lists and maps

$colors: (white: ($white, $black), black: ($black, $white), light: ($light, $light-invert), dark: ($dark, $dark-invert), primary: ($primary, $primary-invert), info: ($info, $info-invert), success: ($success, $success-invert), warning: ($warning, $warning-invert), danger: ($danger, $danger-invert)) !default;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 !default;
