.side-bar {
  @extend .hero;
  @extend .is-primary;

  .logo {
    margin: 0.5em 0.5em 1em;

    img {
      width: 140px; } }

  h1.title {
    font-weight: bold;
    margin-bottom: 1em; }

  .subtitle {
    font-weight: bolder;
    font-style: italic; } }


