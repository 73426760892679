ul.feature-icons {
  @extend .columns;
  list-style: none;
  margin-top: 0.75em;
  margin-left: 0;

  .column {
    padding-bottom: 0;
    padding-top: 0; }

  li {
    @extend .column;
    padding: 0 0 0.75em 0;
    line-height: 2.5em;
    white-space: nowrap;

    .icon {
      background: $primary;
      color: $white;
      border-radius: 100%;
      height: 2.5em;
      width: 2.5em;
      line-height: 2.5em;
      text-align: center;
      margin: 0 1em; } } }

